import { Equals, IsDate } from 'class-validator';

import { OrderPositionListRequest, OrderPositionListRequestRaw } from './order-position-list.request';

export type OrderPositionListDownloadRequestRaw = OrderPositionListRequestRaw & {
    dateStart: string | Date;
    dateEnd: string | Date;
};

export class OrderPositionListDownloadRequest extends OrderPositionListRequest {
    @IsDate()
    public dateStart: Date;

    @IsDate()
    public dateEnd: Date;

    @Equals(true, {
        message: o => {
            const obj = o.object as OrderPositionListDownloadRequest;
            const days = Math.floor((obj.dateEnd.getTime() - obj.dateStart.getTime()) / (60 * 60 * 24 * 1000));
            return `Max date range is one year, but requested range is ${days} days`;
        },
    })
    private get checkDateRange(): boolean {
        return !this.dateStart || !this.dateEnd || (this.dateEnd.getTime() <= this.dateStart.getTime() + (60 * 60 * 24 * 366 * 1000));
    }

    constructor(data?: Partial<OrderPositionListDownloadRequestRaw>) {
        super(data);
        this.dateStart = data?.dateStart instanceof Date ? data.dateStart : new Date(new Date(data?.dateStart).setHours(0, 0, 0, 0));
        this.dateEnd = data?.dateEnd instanceof Date ? data.dateEnd : new Date(new Date(data?.dateEnd).setHours(23, 59, 59, 999));
    }
}
